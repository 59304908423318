<template>
  <div>
    <van-nav-bar
      title="Ofertas exclusivas"
      left-arrow
      @click-left="onClickBack"
      fixed
      placeholder
    />
    <div style="height: 15px"></div>
    <van-tabs
      v-model="activeTab"
      type="card"
      swipeable
      animated
      color="#f4f4f4"
      title-inactive-color="#121212"
      title-active-color="#121212"
    >
      <van-tab title="Meses sin intereses">
        <months-no-interest></months-no-interest>
      </van-tab>
      <van-tab title="Promociones">
        <available-promotions></available-promotions>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
// @ is an alias to /src
import AvailablePromotions from "@/components/AvailablePromotions.vue";
import MonthsNoInterest from "@/components/MonthsNoInterest.vue";
import { Toast } from "vant";

export default {
  name: "HomeView",
  components: {
    AvailablePromotions,
    MonthsNoInterest,
  },
  data() {
    return { activeTab: 0 };
  },
  methods: {
    onClickBack() {
      Toast.success("Click Back");
    },
  },
};
</script>

<style lang="css" scoped>
>>> .van-nav-bar__title {
  margin-left: 64px;
}
.van-tabs--card >>> .van-tabs__wrap {
  height: 42px;
}

.van-tabs--card >>> .van-tabs__nav--card {
  background-color: #f4f4f4;
  height: 40px;
  border-radius: 16px;
}
>>> .van-tab__text {
  font-weight: 500;
}
>>> .van-tab {
  margin-left: 3px;
  margin-right: 3px;
}
.van-tabs--card >>> .van-tab--active {
  background-color: white !important;
  height: 32px;
  border-radius: 16px;
  margin-top: 3px;
}
</style>
