<template>
  <div class="offer_card" @click="onClick()">
    <van-row>
      <van-col span="4">
        <div
          style="
            width: 100%;
            min-height: 56px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          "
        >
          <img class="offer-head-photo" v-if="logo" :src="logo" />
          <img class="offer-head-photo" v-else src="../assets/no-img.jpg" />
        </div>
      </van-col>
      <van-col span="15">
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 56px;
          "
        >
          <div class="offer_name">{{ name }}</div>
          <div class="offer_dscp" v-if="descrption">{{ descrption }}</div>
          <div v-if="tags">
            <span
              class="offer_tag"
              v-for="(tag, index) in tags"
              v-bind:key="index"
              >{{ tag }}</span
            >
          </div>
        </div>
      </van-col>
      <van-col span="2" offset="3">
        <div
          style="width: 100%; height: 56px; display: flex; align-items: center"
        >
          <van-icon name="arrow" />
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "OfferCard",
  props: {
    name: String,
    descrption: String,
    logo: String,
    tags: Array,
    onClick: {
      type: Function,
      default() {
        console.log(`onClick - ${this.name}`);
      },
    },
  },
  data() {
    return {
      activeTab: 0,
    };
  },
};
</script>
