<template>
  <div style="padding-left: 15px; padding-right: 15px">
    <h1 class="title">Obtén descuentos exclusivos que sólo Stori te da</h1>
    <p class="sub_title">
      Súper fácil: compra con Stori y disfruta de los descuentos exclusivos.
    </p>

    <div style="margin-top: 16px">
      <div v-for="(offer, index) in availableOffers" :key="'offer' + index">
        <ali-express-offer-card
          v-if="offer.isAE"
          :name="offer.name"
          :descrption="offer.descrption"
          :logo="offer.logo"
          :tags="offer.tags"
          :onClick="offer.onClick"
        />
        <offer-card
          v-else
          :name="offer.name"
          :descrption="offer.descrption"
          :logo="offer.logo"
          :tags="offer.tags"
          :onClick="offer.onClick"
        />
      </div>
    </div>
    <more-offers-coming />
  </div>
</template>

<script>
import OfferCard from "./OfferCard.vue";
import AliExpressOfferCard from "./AliExpressOfferCard.vue";
import MoreOffersComing from "./MoreOffersComing.vue";

import logo_amazon from "../assets/logo-amazon.png";
import logo_mercado_libre from "../assets/logo-mercado-libre.png";
import logo_uber from "../assets/logo-uber.png";
import logo_cinepolis from "../assets/logo-cinepolis.png";
import logo_alsea from "../assets/logo-alsea.png";
import logo_aliexpress from "../assets/logo-aliexpress.png";
import logo_justo from "../assets/logo-justo.png";

export default {
  components: { OfferCard, AliExpressOfferCard, MoreOffersComing },
  name: "AvailablePromotions",
  data() {
    return {
      availableOffers: [
        {
          name: "Crédito AliExpress",
          logo: logo_aliexpress,
          descrption: "Crédito adicional temporal",
          isAE: true,
          onClick: () => {
            window.location.href =
              "https://sale.aliexpress.com/__pc/ym1QYdCzjk.htm";
          },
        },
        {
          name: "30% en Jüsto",
          logo: logo_justo,
          tags: ["Nuevo"],
          descrption:
            "Pagando con Stori en tu primera compra gana 30% en Cashback",
          onClick: () => {
            window.location.href = "https://www.storialianzas.com/justo";
          },
        },
        {
          name: "10% en Uber",
          logo: logo_uber,
          descrption: "Tus primeros tres viajes obtén un 10% en Cashback",
        },
        {
          name: "2X1 en boletos con Cinépolis",
          logo: logo_cinepolis,
          descrption:
            "Pagando con Stori en tu primera compra gana 30% en Cashback",
          onClick: () => {
            window.location.href = "https://www.storialianzas.com/cinepolis";
          },
        },
        {
          name: "30% de descuento en Alsea",
          logo: logo_alsea,
          descrption:
            "En la compra digitales obtén un 15% de Cashback al instante",
        },
        {
          name: "1 mes gratis en UberONE",
          logo: logo_amazon,
          descrption:
            "En la compra digitales obtén un 15% de Cashback al instante",
        },
        {
          name: "10% OFF en Mercado Libre",
          logo: logo_mercado_libre,
          descrption:
            "En una compra mayor de $2,000.00 MXN obtienes un 20% en Cashback",
        },
      ],
    };
  },
};
</script>
