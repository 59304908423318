<template>
  <div style="padding-left: 15px; padding-right: 15px">
    <h1 class="title">
      ¡Paga a tu ritmo y sin intereses! <br />
      &nbsp;
    </h1>
    <p class="sub_title">
      Ahora, todas las compras que realices en estas tiendas podrás diferirlas a
      meses sin intereses.
    </p>

    <div style="margin-top: 16px">
      <offer-card
        v-for="(offer, index) in availableOffers"
        :key="'offer' + index"
        :name="offer.name"
        :logo="offer.logo"
        :tags="offer.tags"
        :onClick="offer.onClick"
      ></offer-card>
    </div>
    <more-offers-coming />
  </div>
</template>

<script>
import OfferCard from "./OfferCard.vue";

import logo_amazon from "../assets/logo-amazon.png";
import logo_shein from "../assets/logo-shein.png";
import logo_mercado_libre from "../assets/logo-mercado-libre.png";
import logo_nintendo from "../assets/logo-nintendo.png";
import logo_xbox from "../assets/logo-xbox.png";
import MoreOffersComing from "./MoreOffersComing.vue";
import router from "@/router";

export default {
  components: { OfferCard, MoreOffersComing },
  name: "MonthsNoInterest",
  data() {
    return {
      activeTab: 0,
      availableOffers: [
        {
          name: "Amazon",
          tags: ["Nuevo"],
          logo: logo_amazon,
          onClick: () => {
            router.push({ name: "msi-amazon" });
          },
        },
        {
          name: "Shein",
          logo: logo_shein,
        },
        {
          name: "Mercado libre",
          logo: logo_mercado_libre,
        },
        {
          name: "Nintendo",
          logo: logo_nintendo,
        },
        {
          name: "Xbox",
          logo: logo_xbox,
        },
      ],
    };
  },
};
</script>
